@import './colors.css';
@import './fonts.css';

html, body {
    height: 100%;
}

.toggle-button {
    background-color: var(--secondary-purple);
    height: 4vh;
    width: 15vw;
    display: flexbox;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.dropdownInput {
    background-color: var(--secondary-purple);
    border-color: var(--primary-purple);
    width: 50vw;
}


.dropdownInput:hover {
    background-color: var(--primary-purple);
    border-color: var(--secondary-purple);
}

.btn-success {
    background-color: var(--secondary-purple);
    border-color: var(--primary-purple);
    width: 50vw;
    --bs-btn-active-bg: var(--text-purple);
}


.dropdownOptions {
    width: 50vw;
}

.grid-container {
    margin-top: 100px;
}

.col {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.category-name {
    font-family: 'beinBlack';
    font-weight: bold;
    font-size: 2vh;
}