@import './colors.css';
@import './fonts.css';

.header {
  background-color: var(--primary-purple);
}

.drop-down {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
}

.drop-down-button-text {
  font-family: 'vipCartoon';
  font-size: 24px;
  padding-inline: 20px;
  align-self: flex-start;
  padding-bottom: -20px;
}
