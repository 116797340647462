@import './colors.css';
@import './fonts.css';

html, body {
    height: 100%;
}
.main-div-bg {
    min-height: 100vh;
    display:flex;
    background-color: var(--background-main-page);
    flex-direction: column;
    align-items: center;
    padding-top: 7%;
}

.input-field {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 60vw;
    padding: 20px;
}

.answer-text-label {
    font-family: 'vipCartoon';
    align-self: center;
    font-family: 'vipCartoon';
    color: var(--primary-puple);
}

.answers-div {
    display: flex;
    flex-direction: row;
    margin-top: 3%;
}

.answers-small {
    align-self: center;
    font-family: 'vipCartoon';
    font-weight: bolder;
    color: var(--primary-purple);
  }