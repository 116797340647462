.cardBox {
    width: 100%;
    height: 8vh;
    position: relative;
    display: grid;
    place-items: center;
    overflow: hidden;
    margin: 0.5vw;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 10px 0px,
      rgba(0, 0, 0, 0.5) 0px 2px 25px 0px;
  }
  
  .card {
    position: absolute;
    width: 95%;
    height: 95%;
    background: #490b82;
    border-radius: 20px;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: #ffffff;
    overflow: hidden;
    padding: 20px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 60px -12px inset,
      rgba(0, 0, 0, 0.5) 0px 18px 36px -18px inset;
  }
  
  .card h3, span {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    font-weight: 800;
    pointer-events: none;
    opacity: 0.23;
  }
  
  .text {
    top: 50%;
  }
  
  .card .content h3 {
    font-size: 3rem;
  }
  
  .card .content p {
    font-size: 1rem;
    line-height: 20px;
  }
  
  .card .content {
    transform: translateY(100%);
    opacity: 0;
    transition: 0.3s ease-in-out;
  }
  
  .card:hover .content {
    transform: translateY(0);
    opacity: 1;
  }
  
  .cardBox::before {
    content: "";
    position: absolute;
    width: 40%;
    height: 150%;
    background: #aa5cf2;
    background: -webkit-linear-gradient(to right, #d900ff68, #a95cf246, #6b02a8);
    background: linear-gradient(to right, #a95cf25d, #6f0989bd, #a95cf283);
    animation: glowing01 5s linear infinite;
    transform-origin: center;
    animation: glowing 5s linear infinite;
  }
  
  @keyframes glowing {
    0% {
      transform: rotate(0);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  