h1 {
    font-size: 3em;
}

h2 {
    font-size: 2em;
}

p {
    font-size: 1em;
}

.is-row {
    display: flex;
    flex-direction: row;
}

.is-column {
    display: flex;
    flex-direction: column;
}

.is-centered {
    justify-content: center;
    align-items: center;
}

.has-padding-top {
    padding-top: 2rem;
}


.has-default-bg {  
    background-color: var(--background-main-page);
    width: 100%;
}

.spacer {
    height: 0.1rem;
    width: 100%;
    margin-block: 2rem;
    background-color: var(--primary-purple);
}

