@import './colors.css';
@import './fonts.css';

.titles-div {
  display:flex;
  background-color: var(--background-main-page);
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-top: 10%;
  padding-bottom: 5%;
  overflow: hidden;
  text-align: center;
}

.carousel-div {
  background: var(--primary-purple);
  max-width: 100vw;
  padding: 20px;
  display: flex;
  justify-content: center;
}

.subtext-small {
  align-self: center;
  font-family: 'vipCartoon';
  color: var(--primary-purple);
}

.text-large {
  align-self: center;
  font-family: 'vipCartoon';
  color: var(--text-purple);
  text-shadow: 1px 3px 0 rgba(0, 0, 0, 1);
  font-size: 64px;
}

.main-button-small {
  font-family: 'vipCartoon';
  font-size: large;
  margin-top: 2%;
}

.button-text {
  font-family: 'vipCartoon';
  font-size: 24px;
  padding-inline: 20px;
  align-self: center;
  justify-self: center;
  padding-bottom: -20px;
}